<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row justify-content-start">
          <b-col cols="12" md="1">
            <div class="form-group">
              <input
                type="number"
                v-model="filters.id"
                placeholder="ID"
                class="form-control"
              />
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                placeholder="Período"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>

          <b-col cols="12" md="1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <div
      class="form-row justify-content-end my-2"
      v-if="$can('Repasse - Reprovar', 'Comercial')"
    >
      <div class="col-md-2">
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-warning btn-block"
          @click="deleteRemittances()"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-hand-thumbs-down cursor-pointer cursor mr-1"
          ></i>
          Reprovar
        </button>
      </div>
    </div>

    <b-card no-body v-if="!submitedFilter" class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="filters.limit"
              :options="limitOptions"
              @input="setlimitSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        sticky-header
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #head(select)>
          <b-form-checkbox
            v-model="selectAllCheckbox"
            @change="toggleSelectAll"
          />
        </template>

        <template #cell(select)="{ item }">
          <b-form-checkbox
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto"
            :value="item.id"
            v-model="selectedItems"
          />
        </template>

        <template #cell(status)="{ item }">
          <span
            :style="`color: ${item.status.color_hex};padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;`"
          >
            {{ item.status.name }}
          </span>
        </template>

        <template #cell(attachments)="{ item }">
          <i class="bi bi-paperclip"></i>
          {{ item.medias.length || 0 }}
        </template>

        <template #cell(orders)="{ item }">
          <strong
            class="text-purple"
            v-b-tooltip.hover.top="'Quantidade de produtos na matrícula.'"
          >
            <i class="bi bi-box"></i> {{ item.orders.length || 0 }}
          </strong>
        </template>

        <template #cell(created_at)="{ item }">
          {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
        </template>

        <template #cell(remittance_date)="{ item }">
          {{
            item.remittance_date
              ? moment(item.remittance_date).format("DD/MM/YYYY")
              : "-"
          }}
        </template>

        <template #cell(action)="data">
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="cursor-pointer cursor"
            @click="openModal(data.item)"
          >
            <i class="bi bi-eye"></i> Visualizar
          </feather-icon>
        </template>
      </b-table>
      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.page"
        :per-page="filters.limit"
        :total-rows="totalRows"
      />
    </b-card>

    <b-card v-else class="mb-0">
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="my-0 py-3 d-flex-center">
            <b-spinner small variant="primary" class="mr-50" /> Buscando
            repasses...
          </p>
        </div>
      </div>
    </b-card>

    <RemittancePreview
      v-if="selectedRemittance"
      :show="modalVisible"
      :remittance="selectedRemittance"
      @close="modalVisible = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  VBTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BFormCheckbox,
  BModal,
  BFormFile,
  BFormGroup,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import RemittancePreview from "./Preview.vue";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BBadge,
    BFormCheckbox,
    BCardBody,
    vSelect,
    BModal,
    BFormFile,
    BFormGroup,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    RemittancePreview,
  },
  data() {
    return {
      submitted: false,
      selectedItems: [],
      filters: {
        status: 3,
        page: 1,
        limit: 100,
      },
      selectAllCheckbox: false,
      submitedFilter: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      selectedRemittance: null,
      modalVisible: false,
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "id",
          label: "ID",
          class: "text-left",
        },
        {
          key: "user.full_name",
          label: "Criado por",
          class: "text-left",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
        },
        {
          key: "attachments",
          label: "Anexos",
          class: "text-center",
        },
        {
          key: "orders",
          label: "Matriculas",
          class: "text-center",
        },
        {
          key: "remittance_date",
          label: "Data do Repasse",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Data de Criação",
          class: "text-center",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      limitOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      moment,
    };
  },
  methods: {
    checkSelectedItems() {
      if (this.selectedItems.length === 0) {
        this.$bvToast.toast("Selecione ao menos um item!", {
          title: "Aviso",
          variant: "warning",
          solid: true,
        });
        return;
      }
    },
    openModal(repasse) {
      this.selectedRemittance = repasse;
      this.modalVisible = true;
    },
    approveRemittances() {
      this.checkSelectedItems();
      this.submitted = true;

      this.$swal({
        title: "Confirmar a aprovação?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("Order/approveRemittances", {
              items: this.selectedItems,
              status: 3,
            })
            .then(() => {
              this.notifyDefault("success");
            })
            .catch(() => {
              this.notifyDefault("danger");
            })
            .finally(() => {
              this.getData();
            });
        }
      });
    },
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems.map((item) => item.id)
        : [];
    },
    submitFilter() {
      this.getData();
    },
    setlimitSelected(obj) {
      this.filters.limit = obj.value;
    },
    deleteRemittances() {
      this.$swal({
        title: "Confirmar a exclusão?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("selectedItems", this.selectedItems);
        if (result.value) {
          this.$store
            .dispatch("Order/deleteRemittancesByIds", this.selectedItems)
            .then(() => {
              this.notifyDefault("success");
            })
            .catch(() => {
              this.notifyDefault("danger");
            })
            .finally(() => {
              this.getData();
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.page = 1;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.page = 1;
    },
    async getData() {
      this.submitedFilter = true;
      this.$store
        .dispatch("Order/listRemittance", this.filters)
        .then((orders) => {
          if (orders) {
            this.dataItems = orders.data;
            this.rowsTable = orders.data.length;
            this.totalRows = orders.meta.total;
            this.filters.page = orders.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
  async mounted() {
    this.getData();

    this.$root.$on("paginate:update", (res) => {
      this.filters.page = res._page;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.limit = res._limit;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar Matrículas",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar o relatório de matrículas.",
        iconHtml: '<i class="bi bi-download"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("Order/enrollmentsExport", {
              filters: {
                rangeDate: this.filters.rangeDate ?? "",
                status: this.filters.status ? this.filters.status.code : "",
                method: this.filters.method ? this.filters.method.code : "",
                classroom: this.filters.classroom
                  ? this.filters.classroom.code
                  : "",
                course: this.filters.course ? this.filters.course.code : "",
                cpf: this.filters.cpf ?? "",
                id: this.filters.id ?? "",
                unit: this.filters.unit ? this.filters.unit.code : "",
              },
            })
            .then((res) => {
              console.log(res);
            })
            .finally(() => {
              this.submitedFilter = false;
            });
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
